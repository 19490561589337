import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import PlainHeaderWithCarousel from "components/ui/base/PlainHeaderWithCarousel";
import RegisterForCustomerSatisfaction from "components/ui/extended/RegisterForCustomerSatisfaction";
import Customers from "components/ui/extended/Customers";
import ReadPost from "components/ui/base/ReadPost";
import TestimonialWithImageDaniele from "components/ui/extended/TestimonialWithImageDaniele";
import NumberSteps from "components/ui/base/NumberSteps";
import VideoPreview from "components/ui/base/VideoPreview";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { SurveyTemplate } from "app-types/template";
import SurveyTemplates from "components/ui/extended/SurveysTemplates";
import FullWidthVideo from "components/ui/base/FullWidthVideo";
import BlogPostsBox from "components/ui/base/BlogPostsBox";

interface SurveysPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    allSurveysTemplates: {
      nodes: SurveyTemplate[];
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: SurveysPageProps) => {
  const { t } = useTranslation();
  const surveyTemplates = data.allSurveysTemplates.nodes;

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.surveys.seo.title")}
        description={t("pages.surveys.seo.description")}
        image={{
          relativePath: "meta/in-app-chat-surveys-templates.jpg",
          alt: "In-App Surveys ",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.surveys.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.surveys.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
        ]}
      />

      <PlainHeaderWithCarousel
        title={t("pages.surveys.title")}
        description={t("pages.surveys.description")}
        ctaRegister={{ show: true }}
        titleMaxWidth={700}
        descriptionMaxWidth={500}
        images={[
          {
            relativePath: "pages/surveys/survey-software-survey-template-1.jpg",
            alt: "Customerly",
          },
          {
            relativePath: "pages/surveys/survey-software-survey-template-2.jpg",
            alt: "Customerly",
          },
          {
            relativePath: "pages/surveys/survey-software-survey-template-3.jpg",
            alt: "Customerly",
          },
          {
            relativePath: "pages/surveys/survey-software-survey-template-4.jpg",
            alt: "Customerly",
          },
          {
            relativePath: "pages/surveys/survey-software-survey-template-5.jpg",
            alt: "Customerly",
          },
          {
            relativePath: "pages/surveys/survey-software-survey-template-6.jpg",
            alt: "Customerly",
          },
          {
            relativePath: "pages/surveys/survey-software-survey-template-7.jpg",
            alt: "Customerly",
          },
          {
            relativePath: "pages/surveys/survey-software-survey-template-8.jpg",
            alt: "Customerly",
          },
          {
            relativePath: "pages/surveys/survey-software-survey-template-9.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/surveys/survey-software-survey-template-10.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/surveys/survey-software-survey-template-11.jpg",
            alt: "Customerly",
          },
          {
            relativePath:
              "pages/surveys/survey-software-survey-template-12.jpg",
            alt: "Customerly",
          },
        ]}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "In-App Surveys",
            path: "/surveys/",
          },
        ]}
      />

      <ReadPost slug={"in-app-surveys"} />

      <TestimonialWithImageDaniele />

      <NumberSteps
        title={t("pages.surveys.steps.title")}
        elements={[1, 2, 3, 4].map((index) => {
          return {
            title: t(`pages.surveys.steps.elements.${index}.title`),
            description: t(`pages.surveys.steps.elements.${index}.description`),
          };
        })}
      />

      <ImageWithDescription
        id={"advanced-targeting"}
        title={t("pages.surveys.1.title")}
        description={t("pages.surveys.1.subtitle")}
        image={{
          relativePath:
            "pages/surveys/choose-business-intelligence-crm-contact-segmentation.jpg",
          alt: t("pages.surveys.1.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <SurveyTemplates showMore templates={surveyTemplates.slice(0, 5)} />

      <ImageWithDescription
        title={t("pages.surveys.2.title")}
        description={t("pages.surveys.2.subtitle")}
        image={{
          relativePath: "pages/surveys/in-app-csat-survey-template.jpg",
          alt: t("pages.surveys.2.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <VideoPreview
        id={"goto-question"}
        title={t("pages.surveys.3.title")}
        paragraph={t("pages.surveys.3.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath: "pages/surveys/survey-go-to-question-logic.mp4",
          },
        ]}
      />

      <FullWidthVideo
        id={"reports"}
        title={t("pages.surveys.4.title")}
        description={t("pages.surveys.4.subtitle")}
        video={{ path: "pages/surveys/surveys-responses-large.mp4" }}
      />

      <VideoPreview
        id={"user-level-answer"}
        title={t("pages.surveys.5.title")}
        paragraph={t("pages.surveys.5.subtitle")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath: "pages/surveys/surveys-answers.mp4",
          },
        ]}
        cta={{
          text: t("pages.surveys.5.cta"),
          link: "/crm/",
        }}
      />

      <RegisterForCustomerSatisfaction />

      <Customers />

      <BlogPostsBox
        category={"surveys"}
        title={"Surveys Articles"}
        maxPosts={3}
        postsPerRow={3}
        currentPostSlug={"/"}
      />
    </GlobalWrapper>
  );
};

export const sureveysQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/in-app-chat-surveys-templates.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    allSurveysTemplates {
      nodes {
        background
        description
        emoji
        survey_template_id
        name
        questions {
          title
          subtitle
          choices {
            value
          }
        }
        survey {
          name
          thankyou_text
        }
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
  }
`;
